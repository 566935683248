import '../assets/css/Services.css';
const Disclaimer = () => {
    return (
      <div className="content-container">
        <section className="row mb-1">
          <h1>Disclaimer</h1>
          <p className='mb-1'>Welcome to Aconcagua Games. Please be advised of the following important information regarding our services and projects:</p>
          <ul>
            <li className='mb-1'><strong>Independent Entity:</strong> Aconcagua Games is an independent software development company that provides video game development and related services to various clients. We have no direct affiliation or partnership with any of our clients' projects.</li>
            <li className='mb-1'><strong>No Financial Manipulation or Advice:</strong> Aconcagua Games does not manipulate prices, provide financial advice, or recommend any investments in our clients' projects, including cryptocurrencies, NFTs, or any other commercial ventures.
Scope of Service: Our involvement with any client project may include development services, marketing support, or operational assistance as per the agreed-upon contract. However, the ultimate responsibility for the project lies with the client.
</li>
            <li className='mb-1'><strong>No Endorsement:</strong> Any mention of client projects, cryptocurrencies, NFTs, or other commercial activities on our website or through our services does not constitute an endorsement or recommendation by Aconcagua Games.
</li>
            <li className='mb-1'><strong>No Liability:</strong> Aconcagua Games shall not be held liable for any losses, damages, or other issues arising from the use of or reliance on our clients' projects, cryptocurrencies, NFTs, or related activities. Users are advised to conduct their own research and due diligence before engaging with any such projects. Aconcagua Games does not provide any financial advise.            </li>
          </ul>
          <p>By using our website or services, you acknowledge and agree to this disclaimer. If you have any questions or concerns, please contact us at hello@aconcaguagames.com.</p>
        </section>
      </div>
    )
  };
  
  export default Disclaimer;