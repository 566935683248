import React from 'react';
import ReactDOM from "react-dom/client";
import { 
  HashRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Services from "./pages/Services";
import Disclaimer from "./pages/Disclaimer";
import Galaxy from "./pages/Galaxy";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="projects" element={<Projects />} />
          <Route path="disclaimer" element={<Disclaimer />} />
          <Route path="galaxy-metaverse" element={<Galaxy />} />
          <Route path="*" element={<Home />} />
        </Route> 
      </Routes>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);