import { Outlet, Link, useLocation } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

const Footer = () => {

  return (
      <div className="footer">

<ScrollToTop />
        <nav className="navbar container">
            <Link to="/" className="navLogo">
                <img alt="Aconcagua logo" src={"/images/branding/aconcagua.svg"}/>
            </Link>
            <ul>
            <li>
                <Link  to="/" className={useLocation().pathname === "/" ? "active-menu" : null} >Home</Link>
            </li>
            <li>
                <Link to="/projects" className={useLocation().pathname === "/projects" ? "active-menu" : null}>Projects</Link>
            </li>
            <li>
                <Link to="/galaxy-metaverse" className={useLocation().pathname === "/galaxy-metaverse" ? "active-menu" : null}>Galaxy Metaverse</Link>
            </li>
            <li>
                <Link to="/services" className={useLocation().pathname === "/services" ? "active-menu" : null} >Services</Link>
            </li>
            <li>
                <a href="https://www.linkedin.com/company/aconcagua-games/" className="footer-icon" target="_blank"><img alt="linkedin" src={"/images/icons/LinkedInFooter.svg"}/></a>
            </li>
            </ul>
        </nav>
        <div className="container">
            <p>Aconcagua Games is a provider of game development and related services for clients, and is not responsible for the operation of any client projects. Aconcagua Games does not engage in price manipulation or provide any financial advice. Responsibility for any losses or outcomes in client projects lies with the project owner, and users should exercise caution when investing. For more details, please read our full disclaimer <Link to="/disclaimer" className={useLocation().pathname === "/disclaimer" ? "active-menu" : null} >here.</Link></p>
        </div>
      </div>
    );
  };
  
export default Footer;