import { Outlet, Link, useLocation } from "react-router-dom"
import { useState } from "react";

const Header = () => {
    const [menuToggle , setMenu]=useState(false)

const changeMenu = () => {    
    setMenu(() => !menuToggle);
}




  return (
      <div className="header">
        <nav className="navbar">
            <Link to="/" className="navLogo">
                <img alt="Aconcagua logo" src={"/images/branding/aconcagua.svg"}/>
            </Link>
            <button id="burger" onClick={()=>{changeMenu()}}>
                <img alt="desplegar menu" src={"/images/icons/burger.svg"}/>
            </button>
            <div onClick={()=>{changeMenu()}} id="mobile-nav" className={menuToggle ? "showMenu" : "hideMenu"} >
                <span id="close-menu" onClick={()=>{changeMenu()}} ><img alt="close" src="/images/icons/close.svg" class=""></img></span>

            </div>
                <ul id="menu" className={menuToggle ? "showMenu" : "hideMenu"}>
                    <li>
                        <Link onClick={()=>{changeMenu()}}  to="/" className={useLocation().pathname === "/" ? "active-menu" : null} >Home</Link>
                    </li>
                    <li>
                        <Link onClick={()=>{changeMenu()}}  to="/projects" className={useLocation().pathname === "/projects" ? "active-menu" : null} >Projects</Link>
                    </li>
                    <li>
                        <Link onClick={()=>{changeMenu()}}  to="/galaxy-metaverse" className={useLocation().pathname === "/galaxy-metaverse" ? "active-menu" : null}>Sports Metaverse</Link>
                    </li>
                    <li>
                        <Link onClick={()=>{changeMenu()}}  to="/services" className={useLocation().pathname === "/services" ? "active-menu" : null}>Services</Link>
                    </li>
                </ul>
        </nav>
      </div>
    );
  };
  
export default Header;